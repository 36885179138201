import React, { useEffect, useState } from "react";
import {
  Typography,
  Input,
  TextField,
  Checkbox,
  Select,
  MenuItem,
  IconButton,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  ListItemSecondaryAction,
  InputLabel,
  FormControl,
  FormControlLabel,
  Dialog,
  DialogContent,
  DialogTitle,
  Tooltip,
  Grid,
  Chip,
  Switch,
} from "@mui/material";
import { Autocomplete, Pagination } from '@mui/material';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import {
  Timeline,
  TimelineItem,
  TimelineSeparator,
  TimelineConnector,
  TimelineDot,
  TimelineContent,
  TimelineOppositeContent,
  DatePicker,
} from '@mui/lab';

import moment from "moment";
import OrganizationIcon from "@mui/icons-material/Business";
import HomeIcon from "@mui/icons-material/Home";
import EditIcon from "@mui/icons-material/Edit";
import BlockIcon from "@mui/icons-material/Block";
import AddUserIcon from "@mui/icons-material/PersonAdd";
import ArchiveIcon from "@mui/icons-material/Archive";
import HistoryIcon from "@mui/icons-material/History";
import ProductSubscriptionIcon from "@mui/icons-material/Security";
import UserBulkUploadIcon from "@mui/icons-material/GroupAdd";
import { Route, Link as RouteLink, Routes, useParams, useNavigate } from "react-router-dom";
import { connect, useDispatch, useSelector } from "react-redux";
import * as organizationActions from "../actions/organizations";
import * as releaseActions from "../actions/releases";
import * as userActions from "../actions/users";
import * as subscriptionsService from "../services/orgLevel/productSubscriptions";
import {
  OrganizationsPageReducer,
  ProductSubscriptionsReducer,
  Reducers,
  ReleasesReducer,
  SubscriptionsHistoryReducer,
} from "../reducers";
import {
  OrganizationType,
  OrganizationFormInput,
  CreateUserFormInput,
  SubscriptionType,
  UpdateSubscriptionInput,
  ReleaseType,
  CustomError,
  ResellerType,
} from "../services/interfaces";
import ReleasesList from "../components/releases";
import SubmitButton from "../components/SubmitButton";
import { UserForm, UserFormProps, UserBulkUpload } from "./users";
import { downloadPackage } from "../services/organizations";
import Permissioned, { resources, actions } from "../permissioned";
import { useSnackbar } from "notistack";
import MandatoryFieldText from "../components/mandatoryFieldsText";
import TagsInput from "../components/tagInput";

interface OrganizationFormProps {
  loading: boolean;
  onSubmit: Function;
  values?: OrganizationFormInput;
  error?: CustomError;
  disabledFields?: Array<string>;
}

interface CreateUserProps extends UserFormProps {
  fetchRoles: Function;
  organizationId: string;
  error?: CustomError;
}

interface ProductSubscriptionsProps {
  organizationId: string;
  productSubscriptions: Array<SubscriptionType>;
  loading: boolean;
  downloadPackage: Function;
  error?: CustomError;
}

interface UpdateProductSubscriptionProps {
  loading: boolean;
  onSubmit: Function;
  values?: UpdateSubscriptionInput;
  error?: CustomError;
}

interface ReleasesProps {
  loading: boolean;
  releases: Array<ReleaseType>;
  productSubscription?: SubscriptionType;
}

const LIMIT = 20;

function OrganizationListComponent() {
  const dispatch = useDispatch<any>();
  const organizationsPage: OrganizationsPageReducer = useSelector<
    Reducers,
    OrganizationsPageReducer
  >((state) => state.organizationsPage);
  React.useEffect(() => {
    dispatch(
      organizationActions.fetchOragnizations({
        limit: LIMIT,
        page: organizationsPage.page,
      })
    );
    return () => {
      dispatch(organizationActions.resetStates());
    };
  }, [dispatch, organizationsPage.page]);

  return (
    <div>
      <Typography component="h1" variant="h5">
        Organizations
      </Typography>
      <div>
        <Grid container alignItems="center" spacing={2}>
          <Grid item>
            <TextField
              size="small"
              label="Search Organization"
              variant="outlined"
              onChange={(e) => {
                dispatch(
                  organizationActions.updateSearchKeyword(e.target.value)
                );
              }}
              onKeyPress={(e) => {
                if (e.key !== "Enter") {
                  return;
                }
                dispatch(
                  organizationActions.fetchOragnizations({
                    keyword: organizationsPage.searchKeyword,
                    limit: LIMIT,
                    page: organizationsPage.page,
                  })
                );
              }}
            />
          </Grid>
        </Grid>
      </div>
      <List>
        {organizationsPage.organizations.map((org: OrganizationType, index) => {
          return (
            <ListItem
              key={org.id}
              sx={{
                backgroundColor: index % 2 === 0 ? "#f1f1f1" : "inherit"
              }}
            >
              <ListItemIcon>
                {org.organization_type === "home" ? (
                  <HomeIcon />
                ) : (
                  <OrganizationIcon />
                )}
              </ListItemIcon>
              <ListItemText
                primary={
                  <>
                    {org.name} <br />
                    {org.id}
                    {!org.is_active ? (
                      <BlockIcon style={{ marginLeft: 5, fontSize: 15 }} />
                    ) : null}
                  </>
                }
                secondary={
                  <React.Fragment>
                    <span>
                      {org.email} |&nbsp;{org.phone}
                    </span>
                    <br />
                    <span>
                      {org.address.line_1}{org.address.line_2},&nbsp;
                      {org.address.city} |
                      &nbsp;{org.address.state} |
                      &nbsp;{org.address.postal_code} |
                      &nbsp;{org.address.country}
                      {org.reseller ? <><br /><span>Reseller: {org.reseller.name} | {org.reseller.id}</span></> : null}
                    </span>
                  </React.Fragment>
                }
              />
              <ListItemSecondaryAction>
                <Permissioned
                  resource={resources.Subscriptions}
                  action={actions.Read}
                >
                  <RouteLink
                    to={`/admin/organizations/${org.id}/product-subscriptions`}
                  >
                    <Tooltip title="Subscriptions">
                      <IconButton edge="end" aria-label="subscriptions" size="large">
                        <ProductSubscriptionIcon />
                      </IconButton>
                    </Tooltip>
                  </RouteLink>
                </Permissioned>
                <Permissioned
                  resource={resources.Users}
                  action={actions.Create}
                >
                  <RouteLink to={`/admin/organizations/${org.id}/users/add`}>
                    <Tooltip title="Add User">
                      <IconButton edge="end" aria-label="add user" size="large">
                        <AddUserIcon />
                      </IconButton>
                    </Tooltip>
                  </RouteLink>
                </Permissioned>
                <Permissioned
                  resource={resources.Users}
                  action={actions.Create}
                >
                  <RouteLink
                    to={`/admin/organizations/${org.id}/users/bulk-add`}
                  >
                    <Tooltip title="User Bulk Upload">
                      <IconButton edge="end" aria-label="bulk upload users" size="large">
                        <UserBulkUploadIcon />
                      </IconButton>
                    </Tooltip>
                  </RouteLink>
                </Permissioned>
                <Permissioned
                  resource={resources.Organizations}
                  action={actions.Update}
                >
                  <RouteLink to={`/admin/organizations/${org.id}/edit`}>
                    <Tooltip title="Edit Organization">
                      <IconButton edge="end" aria-label="edit organization" size="large">
                        <EditIcon />
                      </IconButton>
                    </Tooltip>
                  </RouteLink>
                </Permissioned>
              </ListItemSecondaryAction>
            </ListItem>
          );
        })}
      </List>

      <Pagination
        count={Math.ceil(organizationsPage.count / LIMIT)}
        page={organizationsPage.page}
        variant="outlined"
        shape="rounded"
        onChange={(e: React.ChangeEvent<unknown>, page: number) => {
          dispatch(
            organizationActions.fetchOragnizations({
              keyword: organizationsPage.searchKeyword,
              limit: LIMIT,
              page,
            })
          );
        }}
      />
    </div>
  );
}

function OrganizationForm(props: OrganizationFormProps) {
  const [reseller, setReseller] = React.useState<ResellerType | null>(null);
  const [resellerInput, setResellerInput] = React.useState("");
  const organizationsPage = useSelector<Reducers, OrganizationsPageReducer>(
    (state) => state.organizationsPage
  );
  const dispatch = useDispatch<any>();

  useEffect(() => {
    dispatch(organizationActions.searchResellers(resellerInput));
  }, [dispatch, resellerInput]);

  useEffect(() => {
    const reseller = props.values?.reseller || null;
    setReseller(reseller);
  }, [props.values?.reseller]);

  let nameRef: any = null;
  let emailRef: any = null;
  let phoneRef: any = null;
  let line1Ref: any = null;
  let line2Ref: any = null;
  let cityRef: any = null;
  let stateRef: any = null;
  let postalCodeRef: any = null;
  let countryRef: any = null;
  let orgTypeRef: any = null;
  let isActiveRef: any = null;
  let allowed_domains: string[] = [];

  const onSubmit: Function = () => {
    props.onSubmit({
      name: nameRef?.value,
      email: emailRef?.value,
      phone: phoneRef?.value,
      logo: "",
      address: {
        line_1: line1Ref?.value,
        line_2: line2Ref?.value,
        city: cityRef?.value,
        state: stateRef?.value,
        postal_code: postalCodeRef?.value,
        country: countryRef?.value,
      },
      organization_type: orgTypeRef?.value,
      is_active: isActiveRef?.checked,
      reseller: reseller,
      allowed_domains: allowed_domains,
    });
  };

  return (
    <div>
      <form>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <TextField
              margin="normal"
              variant="standard"
              error={!!props.error?.validationErrors?.name}
              fullWidth
              required
              label="Name"
              defaultValue={props.values?.name}
              inputRef={(ref) => (nameRef = ref)}
            />
          </Grid>
        </Grid>
        {props.values?.reseller ?
          <Grid container spacing={3}>

            <Grid item xs={6}>
              <TextField
                margin="normal"
                variant="standard"
                disabled={true}
                fullWidth
                required
                label="Reseller"
                defaultValue={props.values?.reseller.name}
                inputRef={(ref) => (nameRef = ref)}
              /></Grid></Grid> : null}
            <Grid container spacing={3}>
              <Grid item xs={6}>
                <TextField
                  margin="normal"
                  variant="standard"
                  required
                  fullWidth
                  label="Email"
                  error={!!props.error?.validationErrors?.email}
                  defaultValue={props.values?.email}
                  inputRef={(ref) => (emailRef = ref)}
                />
              </Grid>
              <Grid item xs={6}>
                <TextField
                  margin="normal"
                  variant="standard"
                  fullWidth
                  required
                  label="Phone"
                  placeholder="6471234567"
                  error={!!props.error?.validationErrors?.phone}
                  defaultValue={props.values?.phone}
                  inputRef={(ref) => (phoneRef = ref)}
                />
              </Grid>
            </Grid>
            <Grid container spacing={3}>
              <Grid item xs={6}>
                <TextField
                  margin="normal"
                  variant="standard"
                  fullWidth
                  required
                  label="Address Line 1"
                  error={!!props.error?.validationErrors?.address?.line_1}
                  defaultValue={props.values?.address.line_1}
                  inputRef={(ref) => (line1Ref = ref)}
                />
              </Grid>
              <Grid item xs={6}>
                <TextField
                  margin="normal"
                  variant="standard"
                  label="Address Line 2"
                  defaultValue={props.values?.address.line_2}
                  inputRef={(ref) => (line2Ref = ref)}
                />
              </Grid>
            </Grid>
            <Grid container spacing={3}>
              <Grid item xs={6}>
                <TextField
                  margin="normal"
                  variant="standard"
                  fullWidth
                  required
                  label="City"
                  error={!!props.error?.validationErrors?.address?.city}
                  defaultValue={props.values?.address.city}
                  inputRef={(ref) => (cityRef = ref)}
                />
              </Grid>
              <Grid item xs={6}>
                <TextField
                  margin="normal"
                  variant="standard"
                  fullWidth
                  required
                  label="State"
                  error={!!props.error?.validationErrors?.address?.state}
                  defaultValue={props.values?.address.state}
                  inputRef={(ref) => (stateRef = ref)}
                />
              </Grid>
            </Grid>
            <Grid container spacing={3}>
              <Grid item xs={6}>
                <TextField
                  margin="normal"
                  variant="standard"
                  fullWidth
                  required
                  label="Postal Code"
                  error={!!props.error?.validationErrors?.address?.postal_code}
                  defaultValue={props.values?.address.postal_code}
                  inputRef={(ref) => (postalCodeRef = ref)}
                />
              </Grid>
              <Grid item xs={6}>
                <FormControl margin="normal" fullWidth>
                  <InputLabel variant="standard">Country</InputLabel>
                  <Select
                    variant="standard"
                    style={{ width: 195 }}
                    defaultValue={props.values?.address.country || "Canada"}
                    inputRef={(ref) => (countryRef = ref)}
                  >
                    {["Canada", "United Kingdom", "United States"].map(
                      (country) => (
                        <MenuItem key={country} value={country}>
                          {country}
                        </MenuItem>
                      )
                    )}
                  </Select>
                </FormControl>
              </Grid>
            </Grid>
            <Grid container spacing={3}>
              <Grid item xs={6}>
                <FormControl margin="normal" fullWidth>
                  <InputLabel variant="standard">Organization Type</InputLabel>
                  <Select
                    variant="standard"
                    disabled={props.disabledFields?.includes("organization_type")}
                    style={{ width: 195 }}
                    inputRef={(ref) => (orgTypeRef = ref)}
                    defaultValue={props.values?.organization_type || "corporate"}
                  >
                    <MenuItem key="home" value="home">
                      <HomeIcon style={{ fontSize: 16, marginRight: 5 }} /> Home
                    </MenuItem>
                    <MenuItem key="corporate" value="corporate">
                      <OrganizationIcon style={{ fontSize: 16, marginRight: 5 }} />{" "}
                      Corporate
                    </MenuItem>
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={6}>
                <Permissioned
                  resource={resources.Organizations}
                  action={actions.Read}
                >
                  <Autocomplete
                    clearOnEscape
                    value={reseller}
                    size="small"
                    onChange={(event: any, newValue: ResellerType | null) => {
                      if (newValue?.id) {
                        dispatch(organizationActions.selectReseller(newValue.id));
                      }
                      setReseller(newValue);
                    }}
                    inputValue={resellerInput}
                    onInputChange={(event, newInputValue) => {
                      setResellerInput(newInputValue);
                    }}
                    id="resellers"
                    noOptionsText="No suggestions"
                    options={organizationsPage.resellers}
                    getOptionLabel={(option) => option.name}
                    renderInput={(params) => (
                      <TextField
                        margin="normal"
                        variant="standard"
                        {...params}
                        placeholder="Search"
                        label="Reseller"
                      />
                    )}
                    renderOption={(options: any) => (
                      <TextField
                        variant="standard"
                        margin="none"
                        {...options}
                        InputProps={{ disableUnderline: true }}
                        value={options.key}
                      />
                    )}
                  />
                </Permissioned>
              </Grid>
            </Grid>
            <Grid container spacing={3}>
              <Grid item xs={12}>
                <TagsInput
                  selectedTags={(items: any) => {
                    allowed_domains = items;
                  }}
                  tags={props.values?.allowed_domains || []}
                  fullWidth
                  id="tags"
                  name="Allowed Domains"
                  label="Allowed Domains"
                  placeholder="Type and press Enter add domain"
                />
              </Grid>
            </Grid>
            <div>
              <FormControlLabel
                control={
                  <Checkbox
                    inputRef={(ref) => (isActiveRef = ref)}
                    defaultChecked={props.values?.is_active}
                    inputProps={{ "aria-label": "primary checkbox" }}
                  />
                }
                label="Active"
              />
            </div>
            <MandatoryFieldText
              hasErrors={Boolean(props.error?.validationErrors)}
            />
            <br />
            <div>
              <SubmitButton
                loading={props.loading}
                label="Submit"
                onClick={() => onSubmit()}
              />
            </div>
          </form>
    </div>
  );
}

function CreateUser(props: CreateUserProps) {
  const { fetchRoles, organizationId } = props;
  const [subscriptions, setSubscriptions] = React.useState<
    Array<SubscriptionType>
  >([]);
  useEffect(() => {
    fetchRoles(100, 1, organizationId);
  }, [fetchRoles, organizationId]);
  useEffect(() => {
    subscriptionsService
      .fetchProductSubscriptions(organizationId, 1, 100)
      .then((subs) => setSubscriptions(subs.list))
      .catch(() => setSubscriptions([]));
  }, [organizationId]);

  return (
    <UserForm
      variant={props.variant}
      error={props.error}
      loading={props.loading}
      values={props.values}
      roles={props.roles}
      subscriptions={subscriptions}
      onSubmit={(values: CreateUserFormInput) => {
        values.organization_id = props.organizationId;
        props.onSubmit(values);
      }}
    />
  );
}

function Releases(props: ReleasesProps) {
  const dispatch = useDispatch();
  const releases = useSelector<Reducers, ReleasesReducer>(
    (state) => state.releases
  );
  useEffect(() => {
    dispatch(
      releaseActions.fetchReleases(props.productSubscription?.product.id, 1, 20)
    );
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <ReleasesList
      releases={releases.releases}
      loading={releases.loading}
      downloadProgress={releases.downloadProgress}
      productSubscription={props.productSubscription}
      onDownloadClick={(variant_id: string) => {
        if (props.productSubscription?.id) {
          dispatch(
            releaseActions.downloadPackage(
              props.productSubscription?.id,
              variant_id
            )
          );
        }
      }}
    />
  );
}

function SubscriptionHistory({
  subscription,
}: {
  subscription: SubscriptionType;
}) {
  const dispatch = useDispatch<any>();
  const subscriptionsHistory = useSelector<
    Reducers,
    SubscriptionsHistoryReducer
  >((state) => state.subscriptionsHistory);
  const [start_date, setStartDate] = useState<Date | null>(null);
  const [end_date, setEndDate] = useState<Date | null>(null);
  useEffect(() => {
    dispatch(
      organizationActions.fetchSubscriptionHistory(
        { subscription_id: subscription.id, start_date, end_date },
        1,
        LIMIT
      )
    );
  }, [dispatch, subscription.id, start_date, end_date]);
  return (
    <>
      <Grid container spacing={3}>
        <Grid item xs={6}>
          <LocalizationProvider dateAdapter={AdapterDateFns}>
            <DatePicker
              views={['year', 'month', 'day']}
              inputFormat="MM/dd/yyyy"
              key="date-picker-inline"
              label="Start date"
              value={start_date}
              onChange={(date: Date | null) => setStartDate(date)}
              renderInput={(props) => <TextField margin="normal" variant="standard"{...props} />}
            />
          </LocalizationProvider>
        </Grid>
        <Grid item xs={6}>
          <LocalizationProvider dateAdapter={AdapterDateFns}>
            <DatePicker
              views={['year', 'month', 'day']}
              inputFormat="MM/dd/yyyy"
              key="date-picker-inline"
              label="End date"
              value={end_date}
              onChange={(date: Date | null) => setEndDate(date)}
              renderInput={(props) => <TextField margin="normal" variant="standard" {...props} />}
            />
          </LocalizationProvider>
        </Grid>
      </Grid>

      <Timeline>
        {subscriptionsHistory.subscriptionsHistory.map(
          (subscriptionHistory, index) => (
            <TimelineItem key={subscriptionHistory.id}>
              <TimelineOppositeContent>
                <Typography>
                  {subscriptionHistory.user.first_name}{" "}
                  {subscriptionHistory.user.last_name}
                </Typography>
                <Typography color="textSecondary">
                  {moment(subscriptionHistory.created_timestamp).format(
                    "YYYY-MM-DD"
                  )}
                </Typography>
              </TimelineOppositeContent>
              <TimelineSeparator>
                <TimelineDot />
                {subscriptionsHistory.subscriptionsHistory.length >
                  index + 1 ? (
                  <TimelineConnector />
                ) : null}
              </TimelineSeparator>
              <TimelineContent>
                <Typography>
                  {subscriptionHistory.action.replaceAll("-", " ")}
                </Typography>
                {subscriptionHistory.license_purchase ? (
                  <Tooltip
                    title={subscriptionHistory.license_purchase.purchase_hash}
                  >
                    <Chip label="Purchase ID" />
                  </Tooltip>
                ) : null}
                {subscriptionHistory.user_subscription?.user ? (
                  <Typography>
                    {subscriptionHistory.user_subscription.user.first_name}{" "}
                    {subscriptionHistory.user_subscription.user.last_name}
                  </Typography>
                ) : null}
                {Diffs(subscriptionHistory.changes)}
              </TimelineContent>
            </TimelineItem>
          )
        )}
      </Timeline>

      {subscriptionsHistory.count ? (
        <Pagination
          count={Math.ceil(subscriptionsHistory.count / LIMIT)}
          page={subscriptionsHistory.page}
          variant="outlined"
          shape="rounded"
          onChange={(e: React.ChangeEvent<unknown>, page: number) => {
            dispatch(
              organizationActions.fetchSubscriptionHistory(
                { subscription_id: subscription.id, start_date, end_date },
                page,
                LIMIT
              )
            );
          }}
        />
      ) : null}
    </>
  );
}

function Diffs(changes: { old: string; new: string } | null) {
  if (!changes) {
    return null;
  }
  let newValues: Record<string, any> = {};
  let oldValues: Record<string, any> = {};
  try {
    newValues = JSON.parse(changes?.new || "{}");
    oldValues = JSON.parse(changes?.old || "{}");
  } catch (err: any) { }
  return (
    <>
      {Object.entries(newValues).map(([key, value]) => {
        let oldValue = oldValues[key];
        if (oldValue === value) {
          return null;
        }

        if (key === "license_expires_at") {
          value = moment.unix(value).format("YYYY-MM-DD");
          oldValue = oldValue
            ? moment.unix(oldValue).format("YYYY-MM-DD")
            : null;
        }

        return (
          <div key={key}>
            <Typography color="textSecondary">
              {key.replaceAll("_", " ")}:
              <span style={{ textDecoration: "line-through" }}>
                {oldValue || null}
              </span>{" "}
              <span>{value || null}</span>
            </Typography>
          </div>
        );
      })}
    </>
  );
}

export function ProductSubscriptions(props: ProductSubscriptionsProps) {
  const dispatch = useDispatch<any>();
  const { enqueueSnackbar } = useSnackbar();
  const productSubscriptions = useSelector<
    Reducers,
    ProductSubscriptionsReducer
  >((state) => state.productSubscriptions);
  useEffect(() => {
    dispatch(
      organizationActions.fetchProductSubscriptions(
        props.organizationId,
        1,
        LIMIT
      )
    );
  }, [dispatch, props.organizationId]);

  const { organizationId } = props;
  const now = moment();
  return (
    <div>
      <List>
        {!productSubscriptions.productSubscriptions.length &&
          "This organization does not have any product subscription"}
        {productSubscriptions.productSubscriptions.map(
          (sub: SubscriptionType) => {
            const expiryDate = moment(new Date(Number(sub.license_expires_at) * 1000));
            const warnDate = moment(new Date(Number(sub.license_expires_at) * 1000)).subtract('days', 30);
            const color = now.isBefore(expiryDate) && now.isAfter(warnDate) ? '#FFBF00' :
              now.isBefore(expiryDate) ? '#4BB543' : '#FF0000';
            return (
              <ListItem key={sub.id}>
                <ListItemText
                  primary={
                    <>
                      {sub.product.name}{" "}
                      <Chip label={sub.subscription_type} size="small" />
                    </>
                  }

                  secondary={
                    <>
                      License type: {sub.license_type} <br />
                      <span style={{ color: color }}>License expires at:</span><span style={{
                        color: color, fontWeight: 600
                      }}>{" "}
                        {moment
                          .unix(sub.license_expires_at)
                          .format("YYYY-MM-DD")}{" "}</span>
                      <br />
                      {sub.product.name != "SAMI" ? (`Max user license count: ${sub.license_purchased_count}`) : ""}
                      {sub.product.name != "SAMI" ? <br /> : ""}
                      {sub.product.name != "SAMI" && `Max device (Installation) license count:${" "}: ${sub.device_license_purchased_count}`}
                      {sub.product.name != "SAMI" ? <br /> : ""}
                      Active user (subscription) count:{" "}
                      {sub.total_active_user_subscriptions} out of{" "}
                      {sub.license_purchased_count}
                    </>
                  }
                />
                <ListItemSecondaryAction>
                  {(() => {
                    if (sub) {
                      return <>
                        <RouteLink
                          to={`/admin/organizations/${props.organizationId}/product-subscriptions/${sub.id}/history`}
                        >
                          <Tooltip title="History">
                            <IconButton edge="end" aria-label="subscription history" size="large">
                              <HistoryIcon />
                            </IconButton>
                          </Tooltip>
                        </RouteLink>
                        {/*
                        disabled for now until we make a call to decide whether or not to keep this
                        <Permissioned
                          resource={resources.Releases}
                          action={actions.Download}
                        >
                          <RouteLink
                            to={`/admin/organizations/${props.organizationId}/product-subscriptions/${sub.id}/releases`}
                          >
                            <Tooltip title="Releases">
                              <IconButton
                                edge="end"
                                aria-label="product releases"
                              >
                                <ArchiveIcon />
                              </IconButton>
                            </Tooltip>
                          </RouteLink>
                        </Permissioned>
                        */}

                        <Permissioned
                          resource={resources.Subscriptions}
                          action={actions.Update}
                        >
                          <RouteLink
                            to={`/admin/organizations/${props.organizationId}/product-subscriptions/${sub.id}/edit`}
                          >
                            <Tooltip title="Edit Subscription">
                              <IconButton edge="end" aria-label="edit product subscription" size="large">
                                <EditIcon />
                              </IconButton>
                            </Tooltip>
                          </RouteLink>
                        </Permissioned>
                        <Permissioned
                          resource={resources.Subscriptions}
                          action={actions.UpdateStatus}
                        >
                          <Tooltip
                            title={sub.is_active ? "Disable" : "Enable"}
                          >
                            <Switch
                              checked={sub.is_active}
                              onChange={() => {
                                dispatch(
                                  organizationActions.updateProductSubscriptionStatus(
                                    sub.id,
                                    organizationId,
                                    !sub.is_active
                                  )
                                )
                                  .then(() =>
                                    enqueueSnackbar(
                                      `Subscription ${sub.is_active ? "disabled" : "enabled"
                                      }`,
                                      { variant: "success" }
                                    )
                                  )
                                  .then(() =>
                                    dispatch(
                                      organizationActions.fetchProductSubscriptions(
                                        organizationId,
                                        productSubscriptions.page,
                                        LIMIT
                                      )
                                    )
                                  )
                                  .catch((err: CustomError) =>
                                    enqueueSnackbar(err.message, {
                                      variant: "error",
                                    })
                                  );
                              }}
                              color="primary"
                            />
                          </Tooltip>
                        </Permissioned>
                      </>;
                    }
                    return null;
                  })()}
                </ListItemSecondaryAction>
              </ListItem>
            );
          }
        )}
      </List>
      {productSubscriptions.count ? (
        <Pagination
          count={Math.ceil(productSubscriptions.count / LIMIT)}
          page={productSubscriptions.page}
          variant="outlined"
          shape="rounded"
          onChange={(e: React.ChangeEvent<unknown>, page: number) => {
            dispatch(
              organizationActions.fetchProductSubscriptions(
                props.organizationId,
                page,
                LIMIT
              )
            );
          }}
        />
      ) : null}
    </div>
  );
}

export function UpdateProductSubscription(
  props: UpdateProductSubscriptionProps
) {
  let licenseCountRef: any;
  let deviceLicenseCountRef: any;
  let maxGraceRef: any;
  let maxGracePeriodRef: any;
  const [selectedDate, setSelectedDate] = React.useState<Date | null>(
    moment().add(1, "year").toDate()
  );
  const [licenseExpireCount, setLicenseExpireCount] = React.useState<
    number | null
  >(1000);
  const [licenseType, setLicenseType] = React.useState<string>("date");
  useEffect(() => {
    if (props.values?.license_type !== undefined) {
      setLicenseType(props.values.license_type);
      if (props.values.license_type === "date") {
        setSelectedDate(moment.unix(props.values.license_expires_at).toDate());
      } else {
        setLicenseExpireCount(props.values.license_expires_at);
      }
    }
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const onSubmit = () => {
    let licenseExpiresAt = null;
    if (licenseType === "date") {
      licenseExpiresAt = Math.round(
        moment(selectedDate).endOf("day").subtract(10, "hours").valueOf() / 1000
      );
    } else {
      licenseExpiresAt = licenseExpireCount;
    }
    props.onSubmit({
      license_type: licenseType,
      license_expires_at: licenseExpiresAt,
      license_purchased_count: Number(licenseCountRef?.value),
      device_license_purchased_count: Number(deviceLicenseCountRef?.value),
      max_grace: Number(maxGraceRef?.value),
      max_grace_period: Number(maxGracePeriodRef?.value),
    });
  };

  return (
    <form>
      <Grid container spacing={3}>
        <Grid item xs={6}>
          <FormControl
            margin="normal"
            fullWidth
            error={!!props.error?.validationErrors?.license_type}
          >
            <InputLabel variant="standard" id="select-license-type">License Type</InputLabel>
            <Select
              variant="standard"
              labelId="select-license-type"
              value={licenseType}
              id="select-license-type"
              onChange={(e: any) => {
                setLicenseType(e.target.value as string);
              }}
            >
              <MenuItem value="date">Date</MenuItem>
              <MenuItem value="index">Index</MenuItem>
            </Select>
          </FormControl>
        </Grid>
        <Grid item xs={6}>
          <FormControl
            fullWidth
            error={!!props.error?.validationErrors?.license_expires_at}
          >
            {licenseType === "index" ? (
              <TextField
                margin="normal"
                variant="standard"
                required
                label="License Expires at"
                type="number"
                style={{ marginTop: "16px", marginBottom: "8px" }}
                value={licenseExpireCount}
                onChange={(e: React.ChangeEvent<{ value: unknown }>) =>
                  setLicenseExpireCount(e.target.value as number)
                }
              />
            ) : (
              <LocalizationProvider dateAdapter={AdapterDateFns}>
                <DatePicker
                  views={['year', 'month', 'day']}
                  inputFormat="MM/dd/yyyy"
                  key="date-picker-inline"
                  label="License Expires on"
                  value={selectedDate}
                  onChange={(date: Date | null) => setSelectedDate(date)}
                  renderInput={(props) => <TextField margin="normal" variant="standard"{...props} />}
                />
              </LocalizationProvider>
            )}
          </FormControl>
        </Grid>
      </Grid>
      <Grid container spacing={3}>
        <Grid item xs={6}>
          <TextField
            margin="normal"
            variant="standard"
            required
            fullWidth
            label="User License Count"
            type="number"
            error={!!props.error?.validationErrors?.license_purchased_count}
            defaultValue={props.values?.license_purchased_count}
            inputRef={(ref) => (licenseCountRef = ref)}
          />
        </Grid>
        <Grid item xs={6}>
          <TextField
            margin="normal"
            variant="standard"
            fullWidth
            required
            error={
              !!props.error?.validationErrors?.device_license_purchased_count
            }
            label="Device License Count"
            type="number"
            defaultValue={props.values?.device_license_purchased_count}
            inputRef={(ref) => (deviceLicenseCountRef = ref)}
          />
        </Grid>
      </Grid>
      <Grid container spacing={3}>
        <Grid item xs={6}>
          <FormControl
            margin="normal"
            fullWidth
            required
            error={!!props.error?.validationErrors?.max_grace}
          >
            <InputLabel variant="standard">Max Grace</InputLabel>
            <Input
              required
              type="number"
              inputProps={{ min: 1 }}
              defaultValue={props.values?.max_grace}
              inputRef={(ref) => (maxGraceRef = ref)}
            />
          </FormControl>
        </Grid>
        <Grid item xs={6}>
          <FormControl
            margin="normal"
            fullWidth
            required
            error={!!props.error?.validationErrors?.max_grace_period}
          >
            <InputLabel variant="standard">Max Grace Period</InputLabel>
            <Input
              required
              type="number"
              inputProps={{ min: 1 }}
              defaultValue={props.values?.max_grace_period}
              inputRef={(ref) => (maxGracePeriodRef = ref)}
            />
          </FormControl>
        </Grid>
      </Grid>
      <br />
      <MandatoryFieldText hasErrors={Boolean(props.error?.validationErrors)} />
      <div>
        <br />
        <SubmitButton
          loading={props.loading}
          onClick={() => onSubmit()}
          label="Submit"
        />
      </div>
    </form>
  );
}

function OrganizationFormModal(props: Reducers & DispatchActions) {
  const navigate = useNavigate();
  const dispatch = useDispatch<any>();
  const { enqueueSnackbar } = useSnackbar();
  const { organizationsPage, updateOrganization } = props;
  interface Params {
    orgId: string
  }
  const { orgId } = useParams<keyof Params>() as Params;
  const organization = organizationsPage.organizations.find(
    (o: any) => o.id === orgId
  );
  return (
    <Dialog open={true} onClose={() => navigate(-1)}>
      <DialogTitle>Update Organization</DialogTitle>
      <DialogContent>
        <OrganizationForm
          disabledFields={["organization_type"]}
          error={updateOrganization.error}
          values={organization}
          loading={updateOrganization.loading}
          onSubmit={(values: OrganizationFormInput) => {
            Promise.resolve()
              .then(() =>
                dispatch(
                  organizationActions.updateOrganization(orgId, values)
                )
              )
              .then(() => navigate(-1))
              .then(() =>
                enqueueSnackbar("Organization updated", {
                  variant: "success",
                })
              )
              .then(() =>
                dispatch(
                  organizationActions.fetchOragnizations({
                    keyword: "",
                    limit: LIMIT,
                    page: 1,
                  })
                )
              )
              .catch((err: CustomError) =>
                enqueueSnackbar(err.message, { variant: "error" })
              );
          }}
        />
      </DialogContent>
    </Dialog>
  );
}

function CreateUserModal(props: Reducers & DispatchActions) {
  const navigate = useNavigate();
  const dispatch = useDispatch<any>();
  const { enqueueSnackbar } = useSnackbar();
  const { createUser } = props;
  interface Params {
    orgId: string
  }
  const { orgId } = useParams<keyof Params>() as Params;
  return (
    <Dialog
      open={true}
      onClose={() => {
        navigate(-1);
        dispatch(userActions.dismissUserMessages());
      }}
    >
      <DialogTitle>Create User</DialogTitle>
      <DialogContent>
        <CreateUser
          variant="create"
          error={createUser.error}
          organizationId={orgId}
          fetchRoles={props.fetchRoles}
          roles={createUser.roles}
          loading={createUser.loading}
          onSubmit={(values: any) => {
            dispatch(userActions.createUser(values))
              .then(() => navigate(-1))
              .then(() =>
                enqueueSnackbar("User created", { variant: "success" })
              )
              .catch((err: CustomError) =>
                enqueueSnackbar(err.message, { variant: "error" })
              );
          }}
        />
      </DialogContent>
    </Dialog>
  );
}

function UserBulkUploadModal(props: Reducers & DispatchActions) {
  const { userBulkUpload, organizationsPage } = props;
  const navigate = useNavigate();
  const dispatch = useDispatch<any>();
  const { enqueueSnackbar } = useSnackbar();
  interface Params {
    orgId: string
  }
  const { orgId } = useParams<keyof Params>() as Params;
  const organization = organizationsPage.organizations.find(
    (o: any) => o.id === orgId
  );

  if (!organization) {
    navigate(-1);
    return null;
  }
  return (
    <Dialog
      open={true}
      maxWidth="xl"
      onClose={() => navigate(-1)}
    >
      <DialogTitle>Bulk User Upload - {organization?.name}</DialogTitle>
      <DialogContent>
        <UserBulkUpload
          organization={organization}
          loading={userBulkUpload.loading}
          onSubmit={(file: File, subscription_id?: string) => {
            dispatch(
              userActions.userBulkUpload(
                orgId,
                file,
                subscription_id
              )
            )
              .then(() => enqueueSnackbar("Upload file processed"))
              .catch((err: CustomError) =>
                enqueueSnackbar(err.message, { variant: "error" })
              );
          }}
          result={userBulkUpload?.uploadResult}
          resetState={props.resetUserBulkUploadStates}
        />
      </DialogContent>
    </Dialog>
  );
}

function ProductSubscriptionsModal(props: Reducers & DispatchActions) {
  const { productSubscriptions } = props;
  const navigate = useNavigate();
  interface Params {
    orgId: string
  }
  const { orgId } = useParams<keyof Params>() as Params;
  return (
    <Dialog
      maxWidth="sm"
      fullWidth={true}
      open={true}
      onClose={() => navigate(-1)}
    >
      <DialogTitle>Product Subscriptions</DialogTitle>
      <DialogContent>
        <ProductSubscriptions
          organizationId={orgId}
          productSubscriptions={
            productSubscriptions.productSubscriptions
          }
          loading={productSubscriptions.loading}
          error={productSubscriptions.error}
          downloadPackage={(
            organizationId: string,
            productId: string
          ) => downloadPackage(organizationId, productId)}
        />
      </DialogContent>
    </Dialog>
  );
}

function UpdateProductSubscriptionModal(props: Reducers & DispatchActions) {
  const { productSubscriptions, updateProductSubscription } = props;
  const navigate = useNavigate();
  const dispatch = useDispatch<any>();
  const { enqueueSnackbar } = useSnackbar();
  interface Params {
    subscriptionId: string
    orgId: string
  }
  const { subscriptionId, orgId } = useParams<keyof Params>() as Params;
  const productSubscription =
    productSubscriptions.productSubscriptions.find(
      (ps) => ps.id === subscriptionId
    );
  if (!productSubscription) {
    return null;
  }
  return (
    <Dialog
      maxWidth="sm"
      fullWidth={true}
      open={true}
      onClose={() => navigate(-1)}
    >
      <DialogTitle>Update Product Subscription</DialogTitle>
      <DialogContent>
        <UpdateProductSubscription
          error={updateProductSubscription.error}
          values={{
            license_type: productSubscription.license_type,
            license_expires_at: productSubscription.license_expires_at,
            license_purchased_count:
              productSubscription.license_purchased_count,
            device_license_purchased_count:
              productSubscription.device_license_purchased_count,
            max_grace: productSubscription.max_grace,
            max_grace_period: productSubscription.max_grace_period,
          }}
          loading={updateProductSubscription.loading}
          onSubmit={(values: UpdateSubscriptionInput) => {
            dispatch(
              organizationActions.updateProductSubscription(
                subscriptionId,
                orgId,
                values
              )
            )
              .then(() => navigate(-1))
              .then(() =>
                dispatch(
                  organizationActions.fetchProductSubscriptions(
                    orgId,
                    1,
                    LIMIT
                  )
                )
              )
              .then(() =>
                enqueueSnackbar("Subscription updated", {
                  variant: "success",
                })
              )
              .catch((err: CustomError) =>
                enqueueSnackbar(err.message, { variant: "error" })
              );
          }}
        />
      </DialogContent>
    </Dialog>
  );
}

function ReleasesModal(props: Reducers & DispatchActions) {
  const navigate = useNavigate();
  interface Params {
    subscriptionId: string
  }
  const { subscriptionId } = useParams<keyof Params>() as Params;
  const { productSubscriptions, releases } = props;
  const subscription = productSubscriptions.productSubscriptions.find(
    (sub) => sub.id === subscriptionId
  );
  return (
    <Dialog open={true} onClose={() => navigate(-1)}>
      <DialogTitle>{subscription?.product.name} Releases</DialogTitle>
      <DialogContent>
        <Releases
          loading={releases.loading}
          releases={releases.releases}
          productSubscription={subscription}
        />
      </DialogContent>
    </Dialog>
  );
}

function SubscriptionHistoryModal(props: Reducers & DispatchActions) {
  const { productSubscriptions } = props;
  const navigate = useNavigate();
  interface Params {
    subscriptionId: string
  }
  const { subscriptionId } = useParams<keyof Params>() as Params;
  const subscription = productSubscriptions.productSubscriptions.find(
    (sub) => sub.id === subscriptionId
  );
  if (!subscription) {
    navigate(-1);
    return null;
  }
  return (
    <Dialog open={true} onClose={() => navigate(-1)}>
      <DialogTitle>
        {subscription?.product.name} Subscription History
      </DialogTitle>
      <DialogContent>
        <SubscriptionHistory subscription={subscription} />
      </DialogContent>
    </Dialog>
  );
}

export function Organizations(
  props: Reducers & DispatchActions
) {

  return (
    <div>
      <OrganizationListComponent />
      <Routes>
        <Route path="/:orgId/edit" element={<OrganizationFormModal {...props} />} />
        <Route path="/:orgId/users/add" element={<CreateUserModal {...props} />} />
        <Route path="/:orgId/users/bulk-add" element={<UserBulkUploadModal {...props} />} />
        <Route path="/:orgId/product-subscriptions" element={<ProductSubscriptionsModal {...props} />} />
        <Route path="/:orgId/product-subscriptions/:subscriptionId/edit" element={<UpdateProductSubscriptionModal {...props} />} />
        <Route path="/:ordId/product-subscriptions/:subscriptionId/releases" element={<ReleasesModal {...props} />} />
        <Route path="/:ordId/product-subscriptions/:subscriptionId/history" element={<SubscriptionHistoryModal {...props} />} />
      </Routes>
    </div>
  );
}

interface DispatchActions {
  fetchRoles: Function;
  submitCreateUser: Function;
  submitUserBulkUpload: Function;
  resetUserBulkUploadStates: Function;
}

const mapStateToProps = (reducers: Reducers) => reducers;
const mapDispatchToProps = (dispatch: any): DispatchActions => {
  return {
    fetchRoles: (limit: number, page: number, organization_id: string) =>
      dispatch(userActions.fetchRoles(limit, page, { organization_id })),
    submitCreateUser: (values: CreateUserFormInput) =>
      dispatch(userActions.createUser(values)),
    submitUserBulkUpload: (organization_id: string, file: File) =>
      dispatch(userActions.userBulkUpload(organization_id, file)),
    resetUserBulkUploadStates: () =>
      dispatch(userActions.resetUserBulkUploadStates()),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Organizations);
