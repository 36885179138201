import * as React from "react";
import {
  Grid,
  Typography,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  ListItemSecondaryAction,
  IconButton,
  Button,
  TextField,
  Dialog,
  DialogContent,
  DialogTitle,
  FormControlLabel,
  Checkbox,
  Tooltip,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
} from "@mui/material";
import { useSnackbar } from "notistack";
import { Pagination } from '@mui/material';
import UserIcon from "@mui/icons-material/Person";
import EditIcon from "@mui/icons-material/Edit";
import BlockIcon from "@mui/icons-material/Block";
import { Route, Link as RouteLink, useNavigate, useParams, Routes } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import * as userActions from "../../actions/autnhive/users";
import Multiselect from "multiselect-react-dropdown";
import {
  CreateUserReducer,
  UpdateUserReducer,
  UsersPageReducer,
} from "../../reducers/autnhive";
import { Reducers } from "../../reducers";
import {
  UserType,
  CustomError,
  RoleType,
  UserFormInput,
  CreateUserFormInput,
} from "../../services/interfaces";
import Permissioned, { actions, resources } from "../../permissioned";
import SubmitButton from "../../components/SubmitButton";
import { useEffect } from "react";
import MandatoryFieldText from "../../components/mandatoryFieldsText";

export interface UserFormProps {
  disableFields?: Array<string>;
  loading: boolean;
  error?: CustomError;
  onSubmit: Function;
  roles: Array<RoleType>;
  values?: UserFormInput;
  variant: "create" | "edit";
}

interface EditUserProps {
  onSubmit: () => void;
  user: UserType;
}

const LIMIT = 20;

function UsersList() {
  const usersPage: UsersPageReducer = useSelector<Reducers, UsersPageReducer>(
    (state) => state.autnhive.usersPage
  );
  const dispatch = useDispatch<any>();
  useEffect(() => {
    dispatch(userActions.fetchUsers(LIMIT, 1));
  }, [dispatch]);
  return <>
    <List>
      {usersPage.users.map((user: UserType, index) => {
        return (
          <ListItem
            key={user.id}
            sx={{
              backgroundColor: index % 2 === 0 ? "#f1f1f1" : "inherit"
            }}
          >
            <ListItemIcon>
              <UserIcon />
            </ListItemIcon>
            <ListItemText
              primary={
                <>
                  {user.first_name} {user.last_name} |{" "}
                  {user.email.toLowerCase()}
                  {!user.is_active ? (
                    <BlockIcon style={{ marginLeft: 5, fontSize: 15 }} />
                  ) : null}
                </>
              }
              secondary={user.user_org_roles.map((ur) => ur.role.name).join(',')}
            />
            <ListItemSecondaryAction>
              <Permissioned
                resource={resources.QboardUsers}
                action={actions.Update}
              >
                <RouteLink to={`/admin/autnhive/users/${user.id}/edit`}>
                  <Tooltip title="Edit User">
                    <IconButton size="large">
                      <EditIcon />
                    </IconButton>
                  </Tooltip>
                </RouteLink>
              </Permissioned>
            </ListItemSecondaryAction>
          </ListItem>
        );
      })}
    </List>

    <Pagination
      count={Math.ceil(usersPage.count / LIMIT)}
      page={usersPage.page}
      variant="outlined"
      shape="rounded"
      onChange={(e: React.ChangeEvent<unknown>, page: number) => {
        dispatch(userActions.fetchUsers(LIMIT, page));
      }}
    />
  </>;
}

export function UserForm(props: UserFormProps) {
  let firstNameRef: HTMLInputElement;
  let lastNameRef: HTMLInputElement;
  let emailRef: HTMLInputElement;
  let isActiveRef: HTMLInputElement;
  let roleIdRef: HTMLInputElement;
  let countryRef: HTMLInputElement;

  const onSubmit: Function = () => {
    props.onSubmit({
      first_name: firstNameRef?.value.trim(),
      last_name: lastNameRef?.value.trim(),
      email: emailRef?.value.toLowerCase().trim(),
      is_active: isActiveRef?.checked,
      role_id: roleIdRef?.value,
      user_org_roles: selectedRoles,
      user_roles: selectedRoles,
      address: {
        country: countryRef.value.trim(),
      },
    });
  };

  const { values } = props;
  const [selectedRoles, setSelectedRoles] = React.useState<any>(values?.roles?.map((ur) => ({role_id:ur.role_id})))
  return (
    <div>
      <form
        onSubmit={(e) => {
          e.preventDefault();
          onSubmit();
        }}
      >
        <Grid container spacing={3}>
          {!values?.isAd ? null : 
          <div style={{marginTop:'10px', marginLeft: '20px', marginBottom: '-20px'}}>
            <p>First name, last name, country must be updated through AD-sync</p>
          </div>} 
          <Grid item xs={6}>
            <TextField
              margin="normal"
              variant="standard"
              error={!!props.error?.validationErrors?.first_name}
              required
              label="First name"
              disabled={values?.isAd}
              defaultValue={values?.first_name}
              inputRef={(ref) => (firstNameRef = ref)}
            />
          </Grid>
          <Grid item xs={6}>
            <TextField
              margin="normal"
              variant="standard"
              error={!!props.error?.validationErrors?.last_name}
              required
              label="Last name"
              defaultValue={values?.last_name}
              disabled={values?.isAd}
              inputRef={(ref) => (lastNameRef = ref)}
            />
          </Grid>
        </Grid>
        <Grid container spacing={3}>
          <Grid item xs={6}>
            <TextField
              margin="normal"
              variant="standard"
              error={!!props.error?.validationErrors?.email}
              required
              label="Email"
              disabled={props.disableFields?.includes("email")}
              defaultValue={values?.email}
              inputRef={(ref) => (emailRef = ref)}
            />
          </Grid>
          <Grid item xs={6}>
            <FormControl margin="normal" error={!!props.error?.validationErrors?.role_id}>
            <Multiselect
                id="select-role"
                options={props.roles}
                placeholder="Role"
                displayValue="name" 
                selectedValues={props.roles.filter((role) => values?.roles.map((ur) => ur.role_id).includes(role.id))}
                hidePlaceholder={true}
                onSelect={(event )=>{
                    let currentRole : any = []
                    event.map((ev:any) => {
                      currentRole.push({role_id: ev.id})
                    })
                    setSelectedRoles(currentRole)
                  }
                }
                onRemove={(event)=>{
                  let currentRole : any = []
                    event.map((ev:any) => {
                      currentRole.push({role_id: ev.id})
                    })
                    setSelectedRoles(currentRole)
                }}
                showCheckbox
              ></Multiselect>
              {/* <InputLabel variant="standard" id="select-label">Role</InputLabel>
              <Select
                variant="standard"
                error={!!props.error?.validationErrors?.role_id}
                labelId="select-role"
                style={{ width: 195 }}
                id="select-role"
                defaultValue={values?.role_id || ""}
                inputRef={(ref) => (roleIdRef = ref)}
              >
                {props.roles.map((role: RoleType) => (
                  <MenuItem key={role.id} value={role.id}>
                    {role.name}
                  </MenuItem>
                ))}
              </Select> */}
            </FormControl>
          </Grid>
        </Grid>
        <Grid container spacing={3}>
          <Grid item xs={6}>
            <FormControl margin="normal">
              <InputLabel variant="standard">Country</InputLabel>
              <Select
                variant="standard"
                style={{ width: 195 }}
                inputRef={(ref) => (countryRef = ref)}
                disabled={values?.isAd}
                defaultValue={values?.address.country || "Canada"}
              >
                {["Canada", "United Kingdom", "United States"].map(
                  (country) => (
                    <MenuItem key={country} value={country}>
                      {country}
                    </MenuItem>
                  )
                )}
              </Select>
            </FormControl>
          </Grid>
        </Grid>
        {props.variant === "edit" ? (
          <div>
            <FormControlLabel
              control={
                <Checkbox
                  inputRef={(ref) => {
                    if (ref) {
                      isActiveRef = ref;
                    }
                  }}
                  defaultChecked={
                    values?.is_active !== undefined ? values.is_active : true
                  }
                  inputProps={{ "aria-label": "primary checkbox" }}
                />
              }
              label="Active"
            />
          </div>
        ) : null}
        <MandatoryFieldText
          hasErrors={Boolean(props.error?.validationErrors)}
        />
        <br />
        <div>
          <SubmitButton
            loading={props.loading}
            label="Submit"
            onClick={() => onSubmit()}
          />
        </div>
      </form>
    </div>
  );
}

function EditUser(props: EditUserProps) {
  const dispatch = useDispatch<any>();
  const { enqueueSnackbar } = useSnackbar();
  const updateUser: UpdateUserReducer = useSelector<
    Reducers,
    CreateUserReducer
  >((state) => state.autnhive.updateUser);
  useEffect(() => {
    dispatch(userActions.fetchRoles(1000, 1));
  }, [dispatch]);

  const { user } = props;
  let roles:{ role_id: string}[] = [];
  user.user_org_roles.forEach(role => {
    if (role?.role.id && role.role.id.length) {
      roles.push({role_id: role?.role.id})
    }
  })
  return (
    <UserForm
      variant="edit"
      error={updateUser.error}
      disableFields={["email"]}
      loading={updateUser.loading}
      values={{
        first_name: user.first_name,
        last_name: user.last_name,
        email: user.email,
        address: user.address,
        is_active: user.is_active,
        role_id: user.user_org_roles[0]?.id,
        roles: roles,
        isAd: user.active_directory_id ? true : false
      }}
      roles={updateUser.roles}
      onSubmit={(values: UserFormInput) => {
        dispatch(userActions.updateUser(user.id, values))
          .then(() => enqueueSnackbar("User updated", { variant: "success" }))
          .then(props.onSubmit)
          .catch((err: Error) =>
            enqueueSnackbar(err.message, { variant: "error" })
          );
      }}
    />
  );
}

function CreateUser({ onSubmit }: { onSubmit: () => void }) {
  const dispatch = useDispatch<any>();
  const { enqueueSnackbar } = useSnackbar();
  const createUser: CreateUserReducer = useSelector<
    Reducers,
    CreateUserReducer
  >((state) => state.autnhive.createUser);
  useEffect(() => {
    dispatch(userActions.fetchRoles(1000, 1));
  }, [dispatch]);
  return (
    <UserForm
      variant="create"
      error={createUser.error}
      loading={createUser.loading}
      roles={createUser.roles}
      onSubmit={(values: CreateUserFormInput) => {
        dispatch(userActions.createUser(values))
          .then(() => enqueueSnackbar("User created", { variant: "success" }))
          .then(() => onSubmit())
          .catch((err: Error) =>
            enqueueSnackbar(err.message, { variant: "error" })
          );
      }}
    />
  );
}

function EditModal(){
  interface Params {
    userId: string;
  }
  const { userId } = useParams<keyof Params>() as Params;
  const navigate = useNavigate();
  const dispatch = useDispatch<any>();
  const usersPage: UsersPageReducer = useSelector<Reducers, UsersPageReducer>(
    (state) => state.autnhive.usersPage
  );
  const user = usersPage.users.find(
    (u) => u.id === userId
  );
  if (!user) return null;
  return (
    <Dialog open={true} onClose={() => navigate(-1)}>
      <DialogTitle>Update User</DialogTitle>
      <DialogContent>
        <EditUser
          user={user}
          onSubmit={() => {
            navigate(-1);
            dispatch(userActions.fetchUsers(LIMIT, 1));
          }}
        />
      </DialogContent>
    </Dialog>
  );
}

export function Users() {
  const dispatch = useDispatch<any>();
  const navigate = useNavigate();
  
  return (
    <div>
      <Typography component="h1" variant="h5">
        Users
      </Typography>
      <div>
        <Permissioned resource={resources.QboardUsers} action={actions.Create}>
          <RouteLink to="/admin/autnhive/users/new">
            <Button
              color="secondary"
              size="small"
              variant="outlined"
              startIcon={<UserIcon />}
            >
              New
            </Button>
          </RouteLink>
        </Permissioned>
      </div>
      <UsersList />

      <Routes>
        <Route path="/new" element={<>
          <Dialog open={true} onClose={() => navigate(-1)}>
            <DialogTitle>Create AutnHive User</DialogTitle>
            <DialogContent>
              <CreateUser
                onSubmit={() => {
                  navigate(-1)
                  dispatch(userActions.fetchUsers(LIMIT, 1));
                }}
              />
            </DialogContent>
          </Dialog>
        </>}
        />
        <Route path="/:userId/edit" element={<EditModal />}/>
      </Routes>
    </div>
  );
}

export default Users;
