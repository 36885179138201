import React, { useState, useEffect } from "react";
import {
  Grid,
  FormControl,
  Select,
  InputLabel,
  MenuItem,
  TextField,
  Typography,
} from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { CustomError, UserProfileType } from "../services/interfaces";
import { Errors, SuccessMessage } from "../components/messages";
import SubmitButton from "../components/SubmitButton";
import { GlobalReducer, Reducers } from "../reducers";
import Permissioned, { actions, resources } from "../permissioned";
import * as userProfileActions from "../actions/userProfile";
import { useSnackbar } from "notistack";

interface UserProfilePageProps {
  updateUserProfile: Function;
  loading: boolean;
  userProfile?: UserProfileType;
  error?: CustomError;
  success?: string;
  dismissMessages: Function;
}

export default function UserProfile(props: UserProfilePageProps) {
  const { enqueueSnackbar } = useSnackbar();
  const dispatch = useDispatch<any>();
  const [country, setCountry] = useState("Canada");
  let firstNameRef: HTMLInputElement;
  let lastNameRef: HTMLInputElement;
  const { userProfile } = useSelector<Reducers, GlobalReducer>(
    (state) => state.globe
  );

  useEffect(() => {
    if (userProfile) {
      setCountry(userProfile?.user.address?.country || "Canada");
    }
  }, [userProfile]); // eslint-disable-line react-hooks/exhaustive-deps
  const onSubmit = () => {
    dispatch(
      userProfileActions.updateUserProfile({
        first_name: firstNameRef?.value,
        last_name: lastNameRef?.value,
        address: {
          country,
        },
      })
    )
      .then(() => enqueueSnackbar("Profile updated", { variant: "success" }))
      .catch((err: Error) =>
        enqueueSnackbar(err?.message || "Unable to update profile", {
          variant: "error",
        })
      );
  };

  return (
    <div>
      <Typography component="h1" variant="h5">
        My Profile
      </Typography>

      <Grid container spacing={2}>
        {userProfile?.user.organization?.id ? (
          <Grid item>
            Organization name: {userProfile?.user.organization?.name}
          </Grid>
        ) : null}
      </Grid>
      <form>
        <Grid container spacing={2}>
          <Grid item xs={6}>
            <TextField
              variant="standard"
              margin="normal"
              label="First name"
              defaultValue={userProfile?.user.first_name}
              inputRef={(ref) => (firstNameRef = ref)}
            />
          </Grid>
          <Grid item xs={6}>
            <TextField
              variant="standard"
              margin="normal"
              label="Last name"
              defaultValue={userProfile?.user.last_name}
              inputRef={(ref) => (lastNameRef = ref)}
            />
          </Grid>
        </Grid>
        <Grid container spacing={2}>
          <Grid item xs={6}>
            <TextField
              variant="standard"
              margin="normal"
              disabled
              label="Email"
              defaultValue={userProfile?.user.email}
            />
          </Grid>
        </Grid>

        <Grid container spacing={2}>
          <Grid item xs={6}>
            <FormControl margin="normal">
              <InputLabel variant="standard">Country</InputLabel>
              <Select
                variant="standard"
                style={{ width: 195 }}
                value={country}
                onChange={(e: any) => {
                  if (typeof e.target.value === "string") {
                    setCountry(e.target.value);
                  }
                }}
              >
                {["Canada", "United Kingdom", "United States"].map(
                  (country) => (
                    <MenuItem key={country} value={country}>
                      {country}
                    </MenuItem>
                  )
                )}
              </Select>
            </FormControl>
          </Grid>
        </Grid>
        <br />
        <Permissioned resource={resources.QboardUserProfile} action={actions.Update}>
          <div>
            <SubmitButton
              loading={props.loading}
              label="Submit"
              onClick={() => onSubmit()}
            />
          </div>
        </Permissioned>
      </form>
      <Errors onClose={props.dismissMessages} message={props.error?.message} />
      <SuccessMessage onClose={props.dismissMessages} message={props.success} />
    </div>
  );
}
