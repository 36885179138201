import * as usersService from "../services/users";
import { UpdateUserProfileInput } from "../services/interfaces";

export const userProfileActions: any = {
  updateUserProfile: "UPDATE_USER_PROFILE",
  updateUserProfileSucceeded: "UPDATE_USER_PROFILE_SUCCEEDED",
  updateUserProfileFailed: "UPDATE_USER_PROFILE_FAILED",
  dismissMessages: "USER_PROFILE_DISMISS_MESSAGES",
};

export const updateUserProfile: any =
  (values: UpdateUserProfileInput) => async (dispatch: any) => {
    dispatch({ type: userProfileActions.updateUserProfile });
    try {
      dispatch({
        type: userProfileActions.updateUserProfileSucceeded,
        payload: await usersService.updateUserProfile(values),
      });
    } catch (err) {
      dispatch({
        type: userProfileActions.updateUserProfileFailed,
        payload: err,
      });
      throw err;
    }
  };

export const dismissMessages = () => ({
  type: userProfileActions.dismissMessages,
});
